import React, { lazy, Suspense } from 'react';
import { LoadingPrimary } from '@sharefiledev/flow-web';
import { PiletApi } from '@sharefiledev/sharefile-appshell';

const LazySendForSignFileListContextActionExtension = lazy(
	() => import('./SendForSignFileListContextActionExtension')
);
export const AsyncSendForSignFileListContextActionExtension: React.FC<{
	params: any;
	piletApi: PiletApi;
}> = props => {
	return (
		<Suspense fallback={<LoadingPrimary />}>
			<LazySendForSignFileListContextActionExtension
				params={props.params}
				piletApi={props.piletApi}
			/>
		</Suspense>
	);
};

const LazySignYourselfFileListContextActionExtension = lazy(
	() => import('./SignYourselfFileListContextActionExtension')
);
export const AsyncSignYourselfFileListContextActionExtension: React.FC<{
	params: any;
	piletApi: PiletApi;
}> = props => {
	return (
		<Suspense fallback={<LoadingPrimary />}>
			<LazySignYourselfFileListContextActionExtension
				params={props.params}
				piletApi={props.piletApi}
			/>
		</Suspense>
	);
};

const LazySendForSignCVContextActionExtenison = lazy(
	() => import('./SendForSignCVContextActionExtenison')
);
export const AsyncSendForSignCVContextActionExtenison: React.FC<{
	params: any;
	piletApi: PiletApi;
}> = props => {
	return (
		<Suspense fallback={<LoadingPrimary />}>
			<LazySendForSignCVContextActionExtenison
				params={props.params}
				piletApi={props.piletApi}
			/>
		</Suspense>
	);
};

const LazySignYourselfCVContextActionExtension = lazy(
	() => import('./SignYourselfCVContextActionExtension')
);
export const AsyncSignYourselfCVContextActionExtension: React.FC<{
	params: any;
	piletApi: PiletApi;
}> = props => {
	return (
		<Suspense fallback={<LoadingPrimary />}>
			<LazySignYourselfCVContextActionExtension
				params={props.params}
				piletApi={props.piletApi}
			/>
		</Suspense>
	);
};
