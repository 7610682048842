import UrlPattern from 'url-pattern';
export const basePath = '/signaturerequests';
export const BaseRoutePattern = new UrlPattern(basePath);

// New Document
export const newDocumentPath = basePath + '/new';
export const newDocumentRoutePattern = new UrlPattern(newDocumentPath);

// Start Document With File Id
export const startDocumentWorkflowWithFilePath =
	basePath + '/files/:fileId/:workflow/start';
export const StartDocumentWorkflowWithFileRoutePattern = new UrlPattern(
	startDocumentWorkflowWithFilePath
);

// Start Document
export const startDocumentPath = basePath + '/:transactionId/start';
export const StartDocumentRoutePattern = new UrlPattern(startDocumentPath);

// Prepare Document
export const prepareDocumentPath = basePath + '/:transactionId/prepare';
export const PrepareDocumentRoutePattern = new UrlPattern(prepareDocumentPath);

// Review And Send Document
export const reviewAndSendDocumentPath = basePath + '/:transactionId/review';
export const ReviewAndSendDocumentRoutePattern = new UrlPattern(
	reviewAndSendDocumentPath
);

// Dashboard
export const signDashboardPath = `${basePath}/dashboard`;
export const SignatureRequestWithTransactionIdRoutePattern = new UrlPattern(
	basePath + '/:transactionId/*'
);
export const templatesPath = `${basePath}/templates`;

// Signature Request Item
export const signatureRequestItemPath = `${basePath}/:documentId/details`;
export const SignatureRequestItemRoutePattern = new UrlPattern(signatureRequestItemPath);

// Default Redirect Path
export const defaultRedirectPath = '/signaturerequests/dashboard';

// New Document creation
export const createDocumentPath = basePath + '/create';
export const createDocumentRoutePattern = new UrlPattern(createDocumentPath);
// Signer Page
export const signerPagePath = `${basePath}/signers/:signerId/sign`;
export const SignerPageRoutePattern = new UrlPattern(signerPagePath);
// Preview Page
export const previewPagePath = `${basePath}/:documentId/view`;
export const PreviewPageRoutePattern = new UrlPattern(previewPagePath);
