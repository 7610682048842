import * as React from 'react';
import { UserRole } from '@citrite/sf-api';
import { SendForSignatureActionIcon } from '@sharefiledev/flow-web';
import type { PiletApi, PiletUserContext } from '@sharefiledev/sharefile-appshell';
import { SEND_FOR_SIGNATURE_ENTITLEMENT } from './constants';
import { acquireAccessToken } from './data/acquireAccessToken';
import { configureESignatureClient } from './data/eSignatureClient';
import { getRSConfig, isLocalRSSetup } from './data/rightSignatureConfiguration';
import { AsyncSendForSignatureDrawer, AsyncSignatureRequestTAPDrawer } from './Drawers';
import {
	AsyncSendForSignCVContextActionExtenison,
	AsyncSendForSignFileListContextActionExtension,
	AsyncSignYourselfCVContextActionExtension,
	AsyncSignYourselfFileListContextActionExtension,
} from './Extensions';
import { setLogger } from './logger';
import {
	AsyncCreateDocument,
	AsyncDashboard,
	AsyncNewDocumentPage,
	AsyncPrepareDocumentPage,
	AsyncPreviewPage,
	AsyncRedirectToTemplatesPage,
	AsyncReviewAndSendDocumentPage,
	AsyncSignatureRequestDetailsPage,
	AsyncSignatureRequestHandler,
	AsyncSignerPage,
	AsyncStartDocumentPage,
} from './Pages';
import {
	RegisterSendForSignature,
	SendForSignatureFileListContextAction,
	SignatureDetailsFileListItem,
	SignYourselfFileListContextAction,
} from './RegisterSendForSignature';
import {
	createDocumentPath,
	newDocumentPath,
	prepareDocumentPath,
	previewPagePath,
	reviewAndSendDocumentPath,
	signatureRequestItemPath,
	signDashboardPath,
	signerPagePath,
	startDocumentPath,
	startDocumentWorkflowWithFilePath,
	templatesPath,
} from './routes';
import { esignDrawers } from './sdk';
import { t } from './util';
import {
	IsProductLedGrowthEnabled,
	isSendForSignatureActionAvailable,
	isSignerRouteEnabled,
	showEsignPilet,
} from './util/access';

export function setup(app: PiletApi) {
	setLogger(app.sf.getLogger());
	const rsConfig = getRSConfig(app.sf.workflowsContext.get());
	const { account, entitlements, user }: PiletUserContext = app.sf.piletUserContext.get();
	const isEmployee = user.Roles?.includes(UserRole.Employee);
	configureESignatureClient({
		baseUrl: !isLocalRSSetup()
			? `${window.origin}/io/esign`
			: rsConfig.RIGHTSIGNATURE_BASE_URL,
		useBffAuthProxy: !isLocalRSSetup(),
		getAccessToken: () => acquireAccessToken(rsConfig.RS4_CLIENT_ID),
	});

	// Register Unauthenticated Pages before checking for entitlements
	if (isSignerRouteEnabled()) {
		app.registerPage(signerPagePath, () => <AsyncSignerPage />, {
			unauthenticated: true,
		});
		app.registerPage(previewPagePath, () => <AsyncPreviewPage />, {
			unauthenticated: true,
		});
	}

	const sendForSignatureStatus = entitlements.getEntitlementStatus(
		SEND_FOR_SIGNATURE_ENTITLEMENT
	);

	const isPLGEnabled = IsProductLedGrowthEnabled(app);

	if (!showEsignPilet(account, user, sendForSignatureStatus)) {
		return;
	}

	registerExtensions(app);

	app.registerPage(startDocumentPath, () => <AsyncStartDocumentPage piletApi={app} />);

	app.registerPage(prepareDocumentPath, () => (
		<AsyncPrepareDocumentPage piletApi={app} />
	));

	app.registerPage(reviewAndSendDocumentPath, () => (
		<AsyncReviewAndSendDocumentPage piletApi={app} />
	));

	app.registerPage(newDocumentPath, () => <AsyncNewDocumentPage piletApi={app} />);

	app.registerPage(startDocumentWorkflowWithFilePath, () => (
		<AsyncSignatureRequestHandler piletApi={app} />
	));

	app.registerPage(createDocumentPath, () => <AsyncCreateDocument piletApi={app} />);

	app.registerExtension(
		'urn:sfextslot:sharefile-appshell:folder:item-context-actions',
		({ params }) => (
			<AsyncSendForSignFileListContextActionExtension params={params} piletApi={app} />
		),
		{
			piletSortWeight: 300,
			isAvailable: params =>
				SendForSignatureFileListContextAction.isAvailable(
					params,
					app.sf.piletUserContext.get().account,
					app.sf.piletUserContext.get().user,
					isPLGEnabled
				),
		}
	);

	app.registerExtension(
		'urn:sfextslot:sharefile-appshell:folder:item-context-actions',
		({ params }) => (
			<AsyncSignYourselfFileListContextActionExtension params={params} piletApi={app} />
		),
		{
			piletSortWeight: 2000,
			isAvailable: params =>
				SignYourselfFileListContextAction.isAvailable(
					params,
					app.sf.piletUserContext.get().account,
					app.sf.piletUserContext.get().user,
					isPLGEnabled
				),
		}
	);

	const isSignatureActionAvailable = isSendForSignatureActionAvailable(
		app.sf.piletUserContext.get().user
	);

	if (isEmployee && isSignatureActionAvailable) {
		app.registerExtension(
			'urn:sfextslot:sharefile-contentviewer:context-actions',
			({ params }) => (
				<AsyncSendForSignCVContextActionExtenison params={params} piletApi={app} />
			),
			{
				piletSortWeight: 800,
			}
		);

		app.registerExtension(
			'urn:sfextslot:sharefile-contentviewer:context-actions',
			({ params }) => (
				<AsyncSignYourselfCVContextActionExtension params={params} piletApi={app} />
			),
			{
				piletSortWeight: 900,
			}
		);
	}

	if (isSignatureActionAvailable) {
		// Pages
		app.registerPage(signDashboardPath, () => <AsyncDashboard piletApi={app} />);
		app.registerPage(signatureRequestItemPath, () => (
			<AsyncSignatureRequestDetailsPage piletApi={app} />
		));
		app.registerPage(templatesPath, () => (
			<AsyncRedirectToTemplatesPage piletApi={app} />
		));

		// Extensions
		app.registerExtension(
			'urn:sfextslot:sharefile-appshell:folder:signaturedetails',
			({ params }) => <SignatureDetailsFileListItem {...params} />
		);

		// ShareFile left Nav Component
		app.sf.registerLeftNavComponent({
			title: () => t('dashboard.esignLeftNav'),
			href: signDashboardPath,
			isAvailable: () => isSignatureActionAvailable,
			icon: SendForSignatureActionIcon,
			weight: 350,
		});

		// Drawers
		app.registerDrawer(
			`urn:sfdrawer:task-aggregator:signature_request`,
			props => {
				return <AsyncSignatureRequestTAPDrawer {...props} piletApi={app} />;
			},
			{
				drawerProps: {
					width: '100%',
					zIndex: 5,
					styles: {
						body: {
							padding: 0,
						},
					},
				},
			}
		);
	}

	//Send for Signature Drawer
	app.registerDrawer(
		esignDrawers.sendForSignatureDrawer,
		props => {
			return <AsyncSendForSignatureDrawer {...props} piletApi={app} />;
		},
		{
			drawerProps: {
				width: '0%',
				height: '0%',
				zIndex: -1,
				mask: false,
				styles: {
					body: {
						padding: 0,
					},
				},
			},
		}
	);
}

function registerExtensions(app: PiletApi) {
	RegisterSendForSignature(app, 'urn:sfextslot:sharefile-appshell:dashboard:shortcuts');
}
