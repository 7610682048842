import { useMemo } from 'react';
import {
	EntitlementsContentParams,
	entitlementsPiletModals,
} from '@sharefiledev/entitlements-pilet';
import { usePiletApiContext } from '../../Contexts/PiletApiContext';
import { getLegacyEntitlementContentParams } from '../../data/getLegacyEntitlementContentParams';
import { RSAccountStatSetResponse } from '../../data/rsTypes';
import { isLegacyEntitlements } from '../../util/isLegacyEntitlements';

type AppShellModalTypes = typeof entitlementsPiletModals.entitlementsModal;

export function useAppshellModal() {
	const { piletApi } = usePiletApiContext();

	const fns = useMemo(() => {
		const showAppShellModal = (
			modal: AppShellModalTypes,
			params?: EntitlementsContentParams
		) => {
			piletApi.showModal(modal, params);
		};

		return {
			entitlementPaywall: (accountStatSetResponse: RSAccountStatSetResponse) => {
				if (isLegacyEntitlements(accountStatSetResponse)) {
					showAppShellModal(
						entitlementsPiletModals.entitlementsModal,
						getLegacyEntitlementContentParams(accountStatSetResponse)
					);
				} else {
					showAppShellModal(
						entitlementsPiletModals.entitlementsModal,
						accountStatSetResponse.entitlementEvaluation
					);
				}
			},
		};
	}, [piletApi]);

	return fns;
}
