/* istanbul ignore file */
import { createScopedInstance } from '@citrite/translate';
import { languages } from '../locales';
export const { t: tmp } = createScopedInstance({
	resources: languages,
	namespace: 'esign-pilet-ui',
	interpolation: {
		escapeValue: false,
	},
});
export const t: (str: string, opts?: any) => string = tmp;
