import { getFeatureFlagValue } from '@citrite/feature-flags';
import { Account, IntegrationProvider, User, UserRole } from '@citrite/sf-api';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { SEND_FOR_SIGNATURE_ENTITLEMENT } from '../constants';
import { FeatureFlag } from '../FeatureFlags/featureFlagDefinitions';

export const isAccountStorageLimitReached = (account: Account) => {
	return (
		getFeatureFlagValue(FeatureFlag.EnableStorageEnforcement) &&
		account.DiskSpace?.EnforceStorageUsage
	);
};

export const isSendForSignatureActionAvailable = (user: User) => {
	return (
		user.Roles.includes(UserRole.CanSendDocumentsForSignature) &&
		!user.Roles.includes(UserRole.Client)
	);
};

export const isSignerRouteEnabled = () => {
	return getFeatureFlagValue(FeatureFlag.EnableEsignSignerRoute);
};

export const IsProductLedGrowthEnabled = (piletApi: PiletApi): boolean => {
	const { entitlements } = piletApi.sf.piletUserContext.get();

	return entitlements
		.getEntitlementStatusFull(SEND_FOR_SIGNATURE_ENTITLEMENT)
		.attributes.includes('paywall');
};

export const isAccountCreatedBefore30May2024 = (account: Account): boolean =>
	new Date(account.CreationDate) < new Date('2024-05-30');

export function showEsignPilet(
	account: Account,
	user: User,
	entitlementStatus: boolean
): boolean {
	if (!user.Roles.includes(UserRole.CanSendDocumentsForSignature)) {
		return false;
	}

	if (getFeatureFlagValue(FeatureFlag.DisableRightSignature)) {
		return false;
	}

	if (
		!(
			account.Preferences.EnableIntegrations &&
			(account.Preferences.IntegrationProviders.includes(
				IntegrationProvider.RightSignature4
			) ||
				entitlementStatus)
		)
	) {
		return false;
	}

	if (!getFeatureFlagValue(FeatureFlag.EnableEsignPilet)) {
		return false;
	}

	return true;
}
