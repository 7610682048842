import { EntitlementsContentParams } from '@sharefiledev/entitlements-pilet';
import { IconProps } from '@sharefiledev/icons/dist/typings/Icon';
import { ComponentTypes } from '../Common/Components/types';

export interface DocumentsResponse {
	dashboard_documents: ReadonlyArray<RSDocument>;
	meta: {
		total_pages: number;
	};
}

export interface RSDocument {
	account_id: string;
	executed_at: string;
	expired_at: Date;
	filename: string;
	id: string;
	kba: boolean;
	model: string; // Looks to be an enum, need more data
	name: string;
	next_signer_id: string;
	sender: DocumentSender;
	sent_at: Date;
	expires_at: Date;
	signers: DocumentSigner[];
	state: DocumentStates;
	tags?: object;
	thumbnail_url: string;
	type: string;
	user_id: string;
	workflow: string;
}

export interface ComponentValue {
	component_id: string;
	document_id: string;
	font_size?: number;
	id: string;
	value: string;
}

export interface DocumentResponse {
	accounts: Account[];
	base_files: BaseFiles[];
	component_values: ComponentValue[];
	document: Readonly<ExtendedRSDocument>;
	document_signed_base_files: any;
	mergefield_values: any;
	responses: SignerResponse[];
	roles: Role[];
	signers: DocumentSigner[];
	signer_payments: SignerPayment[];
	uploads: any;
	attachments?: Attachment[];
	viewers?: Array<{ id: string; email: string; name: string }>;
}

export interface ExtendedRSDocument {
	account_id: string;
	audits: any;
	components?: Component[];
	id: string;
	decline_reason: string;
	document_certificate_url: any;
	document_state: DocumentStates;
	document_sent_at: Date;
	document_expires_at: Date;
	merged_document_certificate_url: string;
	next_signer_id: string;
	name: string;
	preview_url?: string;
	sender_name: string;
	signed_pdf_url: string;
	sender_email: string;
	signer_failed_passcode_attempts: boolean;
	signer_ids: string[];
	signer_sequencing: boolean;
	shared_with: string[];
	viewers?: Array<{ id: string; email: string; name: string }>;
	type: string;
	void_reason: string;
	workflow: string;
	passcode: boolean;
	kba: boolean;
	kba_locked: boolean;
	transaction_id: string;
	filename?: string;
	from_reusable_template?: boolean;
	sender_allow_drawn_signature?: boolean;
	sender_allow_typed_signature?: boolean;
}

export interface SignerRequest {
	accounts: Account[];
	attachments?: Attachment[];
	base_files: BaseFiles[];
	component_values: ComponentValue[];
	documents: ReadonlyArray<ExtendedRSDocument>;
	document_signed_base_files: any;
	mergefield_values: any;
	responses: SignerResponse[];
	roles: Role[];
	signer: DocumentSigner;
	signers: DocumentSigner[];
	signer_payments: SignerPayment[];
	uploads: any;
}

export interface SignerSubmissionRequest {
	signer: {
		name?: string;
		email?: string;
		response: {
			status: SignerResponseStatus;
			fingerprint_data?: {
				audits: [
					{
						action: string;
						timestamp: string;
					}
				];
				browser_fingerprint?: any;
			};
			component_values: ComponentValue[];
		};
	};
}

export enum SignerResponseStatus {
	Draft = 'draft',
	Submitted = 'submitted',
}
export interface SignerSubmissionResponse {
	signer: {
		id: string;
		status: string;
		existing_user_by_email?: any;
		redirect_token: string;
	};
	document: {
		id: string;
		document_state: DocumentStates;
		identity_method: string;
	};
}

export interface Account {
	id: string;
	name: string;
	sharefile_account_id: string;
	subdomain: string;
	enable_signer_download: boolean;
}

export interface Attachment {
	component_id: string;
	filename: string;
	id: string;
	url: string;
}

export interface Component {
	id: string;
	assigned_to: string;
	type: string;
	x?: number;
	y?: number;
	height?: number;
	width?: number;
	name?: string;
	page?: number;
	font_size?: number;
	is_required: boolean;
	multiline?: boolean;
	transparent?: boolean;
	text_align?: string;
	value?: string;
	help_text?: string;
	date_format?: string;
	is_date_signed?: boolean;
	select_options?: string[];
	group_id?: number;
	group_items?: Component[];
	group_requirement?: GroupRequirement;
	charge_after_executed: boolean;
	payment_amount?: number;
	signature_id?: string;
}

export enum GroupRequirement {
	OnlyOne = 'only_one',
	OneOrMore = 'one_or_more',
}

export interface DocumentSender {
	email: string;
	name: string;
	id?: string;
	signer?: boolean;
	roleName?: string;
}

export interface DocumentSigner {
	email: string;
	id: string;
	name: string;
	role?: number;
	role_name: string;
	message?: string;
	failed_passcode_validation?: boolean;
	failed_kba_validation?: boolean;
	state?: string;
	status?: string; // should be an enum
	is_sender?: boolean;
	sequence?: number;
}
export interface DocumentViewer {
	id: string;
	email: string;
	name: string;
}

export interface DocumentTransactionRequest {
	document_transaction: DocumentTransaction;
	documents?: ExtendedRSDocument[];
}

export interface DocumentTransaction {
	id: string;
	document_ids: any;
	account_id: string;
	user_id: string;
	workflow: WorkflowTypes;
	signers_count?: number;
	signers_csv_filename?: string;
}

export enum DocumentStates {
	Draft = 'draft',
	Pending = 'pending',
	Executed = 'executed',
	Voided = 'voided',
	Expired = 'expired',
	Declined = 'declined',
	Editing = 'editing',
}

export enum SignerStatus {
	Pending = 'pending',
	Signed = 'signed',
	PendingIdentityAuthentication = 'pending_identity_authentication',
	Declined = 'declined',
}

export interface SignerResponse {
	attachment_ids: string[];
	component_value_ids: string[];
	fingerprint_data: any;
	id: string;
	signer_id: string;
	status: string;
}

export interface SignerPayment {
	card_str: string;
	charge_after_executed: boolean;
	charge_failure_code: string;
	charge_successful: boolean;
	component_id: string;
	id: string;
	payment_amount: number;
	payment_amount_dollars_formatted: string;
	payment_last4: string;
	payment_type: string;
	signer_id: string;
	to_audit_str: string;
}

export interface Role {
	id: string;
	is_sender: boolean;
	name: string;
	signer_email: string;
	signer_name: string;
	sequence?: number;
	signer_id?: string;
}

export interface SignatureResponse {
	signature: Signature;
}

export interface Signature {
	color: string;
	id?: string;
	image_url: string;
	is_default: boolean;
	json_data: string;
	signature_text: string;
	signature_type: string;
	signer_id: string;
	user_id: string;
	image_data?: string;
	image_data_raw?: string;
	s3_guid?: string;
}

export interface SignaturesResponse {
	signatures: Signature[];
}
export enum WorkflowTypes {
	SignatureRequest = 'signature_request',
	SelfSign = 'self_sign',
	Packager = 'packager',
	BulkSend = 'bulk_send',
}

export interface IComponentPayload {
	id: string;
	type: ComponentTypes;
	x: number;
	y: number;
	width: number;
	height: number;
	page: number;
	name: string;
	assigned_to: string;
	is_required: boolean;
	group_requirement?: string;
	group_name?: string;
	group_id?: string;
	font_size?: number;
	multiline?: boolean;
	transparent?: boolean;
	value?: string;
	text_align?: string;
	select_options?: string[];
	help_text?: string;
	charge_after_executed?: boolean;
	payment_amount?: number;
	is_merge_field?: boolean;
	date_format?: string;
	is_source_mapped?: boolean;
	document_template_id: string;
	document_template_type: string;
	signature_id?: string;
	is_annotation: boolean;
}

export interface BaseFiles {
	page_images?: any;
	downloaded_file_url: string;
	processed_pdf_url: string;
	uploaded_file_url?: string;
	page_count: number;
}

export interface IPasscodePin {
	pin: string;
}

export interface IUserSettingsPayload {
	attach_pdf_on_completion?: boolean;
	allow_drawn_signature?: boolean;
	allow_typed_signature?: boolean;
	reminder_frequency?: string;
}

export interface IAccountSettingsPayload {
	signature_color_blue_default?: boolean;
}

export interface IReusableTemplateQueryParams {
	is_bulk_send?: boolean;
	has_merge_field?: boolean;
	search?: string;
	per_page: number;
	page: number;
}

export interface ICloneDocumentPayload {
	native_esign_plugin: boolean;
}

export interface IReusableTemplateItem {
	account_id: string;
	creator: { name: string; email: string };
	filename: string;
	id: string;
	model: string;
	name: string;
	tags: {};
	thumbnail_url: string;
	type: string;
	user_id: string;
	role_names: string[];
	signer_roles_count: number;
}
export interface IReusableTemplateResponse {
	dashboard_reusable_templates: IReusableTemplateItem[];
	meta: {
		total_pages: number;
	};
}

export interface IMergeTransactionResponse {
	merge_transaction: {
		id: string;
		merged_upload_id: string;
		status: string;
		upload_sequence: {};
		total_upload_size: number;
		document_transaction_id: string;
	};
}

export interface PaymentPayload {
	token: string;
}

export interface DeclineDocumentRequest {
	reason_for_decline: string;
	identity_token?: string;
	access_token?: string;
}

export interface PasscodeInformation {
	passcode_pin_enabled: boolean;
	passcode_question: string;
	failed_passcode_attempts: number;
	failed_passcode_validation: boolean;
	failed_passcode_message: string;
}

export interface PasscodeValidationResult {
	passcode_valid: boolean;
	passcode_answer_digest: string;
}

export interface SignerUnauthorizedError {
	message: string;
	sender_email: string;
}

export enum SignerUnauthorizedStates {
	DocumentIsExecuted = 'executed',
	SignedBySigner = 'Signer is not pending',
	Declined = 'declined',
	OnRevision = 'editing',
	Voided = 'voided',
	NotYouTurn = 'not your turn',
	Expired = 'expired',
	PendingIdentityAuthentication = 'pending identity verification',
	Invalid = 'invalid',
}

export enum ViewerUnauthorizedStates {
	Expired = 'expired',
	Declined = 'declined',
	OnRevision = 'revising',
	Voided = 'voided',
	NoAccess = 'not authorized',
	TokenExpired = 'Token Expired',
	Invalid = 'invalid',
}

export interface UnauthorizedContent {
	signerState: SignerUnauthorizedStates;
	viewerState: ViewerUnauthorizedStates;
	title: string;
	description: string;
	boldDescription: string;
	isSuccess: boolean;
	icon: (props: IconProps) => JSX.Element;
}

export enum DownloadType {
	Original = 'original',
	Completed = 'completed',
	Certificate = 'certificate',
	Combined = 'combined',
}

export interface RSAccountStatSetResponse {
	account_stat_set: RSAccountStatSet;
	entitlementEvaluation: EntitlementsContentParams;
}

export interface RSAccountStatSet {
	id: string;
	document_limit: number;
	documents_sent: number;
	template_limit_reached: boolean;
	template_limit: number;
	total_seats: number;
	seats_used: number;
	can_send_document: boolean;
	can_create_template: boolean;
	bulk_send_limit_reached: boolean;
}

export interface ScrollToPageNumber {
	page: number;
}

export enum EidasCertificateType {
	Aes = 'AES',
	Qes = 'QES',
}

export enum RSTokenHeaderTypes {
	IdentityToken = 'Identity-Token',
	AccessToken = 'Access-Token',
	RedirectToken = 'RightSignature-Redirect-Token',
}
