const tokenKey = 'rsJwtToken';
let memoryToken: string | null = null;

export function getCachedToken() {
	const storage = getStorage();
	if (storage) {
		return storage.getItem(tokenKey);
	} else {
		return memoryToken;
	}
}

export function setCachedToken(token: string) {
	const storage = getStorage();
	if (storage) {
		storage.setItem(tokenKey, token);
	} else {
		memoryToken = token;
	}
}

function getStorage() {
	if (typeof window !== undefined && window.sessionStorage) {
		return window.sessionStorage;
	}
}
