import type { WorkflowsContext } from '@sharefiledev/sharefile-appshell';
import { RightSignatureLocalEnv } from './rightSignatureLocalEnv';

export function getRSConfig(workflowContext: WorkflowsContext) {
	let config = {
		RIGHTSIGNATURE_BASE_URL: workflowContext.env.RIGHTSIGNATURE_BASE_URL,
		RS4_CLIENT_ID: workflowContext.env.RS4_CLIENT_ID,
	};

	if (isLocalRSSetup()) {
		config.RIGHTSIGNATURE_BASE_URL = RightSignatureLocalEnv.RIGHTSIGNATURE_BASE_URL;
		config.RS4_CLIENT_ID = RightSignatureLocalEnv.RS4_CLIENT_ID;
	}

	return config;
}

export function isLocalRSSetup(): boolean {
	// try-catch to handle case when env variable not defined, it just throw process not defined error
	try {
		return (
			process.env.NODE_ENV === 'development' &&
			process.env.ESIGN_PILET_USE_LOCAL_RS === 'true'
		);
	} catch {}

	return false;
}
