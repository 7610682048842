import React, { lazy, Suspense } from 'react';
import { LoadingPrimary } from '@sharefiledev/flow-web';
import { PiletApi } from '@sharefiledev/sharefile-appshell';

const LazyReviewAndSendDocument = lazy(() => import('./ReviewAndSendDocument'));
export const AsyncReviewAndSendDocumentPage: React.FC<{ piletApi: PiletApi }> = props => {
	return (
		<Suspense fallback={<LoadingPrimary />}>
			<LazyReviewAndSendDocument {...props} />
		</Suspense>
	);
};

const LazyPrepareDocument = lazy(() => import('./PrepareDocument'));
export const AsyncPrepareDocumentPage: React.FC<{ piletApi: PiletApi }> = props => {
	return (
		<Suspense fallback={<LoadingPrimary />}>
			<LazyPrepareDocument {...props} />
		</Suspense>
	);
};

const LazyStartDocument = lazy(() => import('./StartDocument'));
export const AsyncStartDocumentPage: React.FC<{ piletApi: PiletApi }> = props => {
	return (
		<Suspense fallback={<LoadingPrimary />}>
			<LazyStartDocument {...props} />
		</Suspense>
	);
};

const LazyNewDocument = lazy(() => import('./NewDocument'));
export const AsyncNewDocumentPage: React.FC<{ piletApi: PiletApi }> = props => {
	return (
		<Suspense fallback={<LoadingPrimary />}>
			<LazyNewDocument {...props} />
		</Suspense>
	);
};

const LazySignatureRequestDetails = lazy(() => import('./SignatureRequestDetails'));
export const AsyncSignatureRequestDetailsPage: React.FC<{
	piletApi: PiletApi;
}> = props => {
	return (
		<Suspense fallback={<LoadingPrimary />}>
			<LazySignatureRequestDetails {...props} />
		</Suspense>
	);
};

const LazyRedirectToTemplates = lazy(() => import('./RedirectToTemplates'));
export const AsyncRedirectToTemplatesPage: React.FC<{ piletApi: PiletApi }> = props => {
	return (
		<Suspense fallback={<LoadingPrimary />}>
			<LazyRedirectToTemplates {...props} />
		</Suspense>
	);
};

const LazySignatureRequestHandler = lazy(() => import('./SignatureRequestHandler'));
export const AsyncSignatureRequestHandler: React.FC<{ piletApi: PiletApi }> = props => {
	return (
		<Suspense fallback={<LoadingPrimary />}>
			<LazySignatureRequestHandler {...props} />
		</Suspense>
	);
};

const LazyDashboard = lazy(() => import('./Dashboard'));
export const AsyncDashboard: React.FC<{ piletApi: PiletApi }> = props => {
	return (
		<Suspense fallback={<LoadingPrimary />}>
			<LazyDashboard {...props} />
		</Suspense>
	);
};

const LazyCreateDocument = lazy(() => import('./CreateDocument'));
export const AsyncCreateDocument: React.FC<{ piletApi: PiletApi }> = props => {
	return (
		<Suspense fallback={<LoadingPrimary />}>
			<LazyCreateDocument {...props} />
		</Suspense>
	);
};

const LazySignerPage = lazy(() => import('./SignerPage'));
export const AsyncSignerPage: React.FC = () => {
	return (
		<Suspense fallback={<LoadingPrimary />}>
			<LazySignerPage />
		</Suspense>
	);
};

const LazyPreviewPage = lazy(() => import('./PreviewPage'));
export const AsyncPreviewPage: React.FC = () => {
	return (
		<Suspense fallback={<LoadingPrimary />}>
			<LazyPreviewPage />
		</Suspense>
	);
};
