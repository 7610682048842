import { type EntitlementsContentParams } from '@sharefiledev/entitlements-pilet';
import { RSAccountStatSetResponse } from './rsTypes';

export const getLegacyEntitlementContentParams = (
	accountStats: RSAccountStatSetResponse
): EntitlementsContentParams => {
	return {
		entitlementDefRID: 'sharefile:entitlementdef-send-for-signature',
		entitlementRID: accountStats.entitlementEvaluation.entitlementRID,
		entitled: true,
		evaluationResult: {
			isPaywallEnabled: true,
			showLimitBanner: true,
			limit: `${accountStats.account_stat_set.document_limit}`,
			current: `${accountStats.account_stat_set.documents_sent}`,
			requested: '0',
			remaining: `${
				accountStats.account_stat_set.document_limit -
				accountStats.account_stat_set.documents_sent
			}`,
		},
		httpStatusCodeResponseToCaller: 200,
	};
};
