import * as React from 'react';
import { Account, User } from '@citrite/sf-api';
import type { PiletUserContext } from '@sharefiledev/sharefile-appshell';

export interface ShareFileUserContext {
	sfUser: User;
	sfAccount: Account;
}

interface ShareFileUserContextProviderProps extends Partial<PiletUserContext> {
	children: React.ReactNode;
}

const Context = React.createContext<ShareFileUserContext>({
	sfUser: {},
	sfAccount: {},
});

export function ShareFileUserContextProvider(props: ShareFileUserContextProviderProps) {
	return (
		<Context.Provider
			value={{
				sfUser: props.user,
				sfAccount: props.account,
			}}
		>
			{props.children}
		</Context.Provider>
	);
}

export const useShareFileUserContext = () => React.useContext(Context);
