import React from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';

export interface PiletApiContext {
	piletApi: PiletApi;
}

interface PiletApiContextProviderProps {
	children: React.ReactNode;
	piletApi: PiletApi;
}

const Context = React.createContext<PiletApiContext>({
	piletApi: {} as PiletApi,
});

export const PiletApiContextProvider: React.FC<PiletApiContextProviderProps> = ({
	piletApi,
	children,
}) => {
	return <Context.Provider value={{ piletApi }}>{children}</Context.Provider>;
};

export const usePiletApiContext = () => React.useContext(Context);
